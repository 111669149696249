export const Colors = {
  Dark: 'rgba(23, 22, 21, 1)',
  Gray900: 'rgba(35, 31, 27, 1)',
  Gray800: 'rgba(58, 54, 49, 1)',
  Gray700: 'rgba(82, 78, 72, 1)',
  Gray600: 'rgba(107, 103, 98, 1)',
  Gray500: 'rgba(134, 131, 127, 1)',
  Gray400: 'rgba(161, 157, 153, 1)',
  Gray300: 'rgba(189, 186, 183, 1)',
  Gray200: 'rgba(218, 216, 214, 1)',
  Gray100: 'rgba(245, 244, 242, 1)',
  Gray50: 'rgba(250, 249, 247, 1)',
  KeylineGray: 'rgba(233, 232, 232, 1)',
  WashGray: 'rgba(0, 0, 0, .35)',
  Gray10: 'rgba(35, 31, 27, 0.03)',
  White: 'rgba(255, 255, 255, 1)',
  LightPurple: 'rgba(222, 221, 255, 1)',
  Link: 'rgba(9, 8, 110, 1)',
  Blue700: 'rgba(14, 12, 167, 1)',
  Blue500: 'rgba(79, 67, 221, 1)',
  Blue200: 'rgba(185, 180, 241, 1)',
  Blue100: 'rgba(211, 209,237,1)',
  Blue50: 'rgba(236,236,248, 1)',
  Red700: 'rgba(165, 9, 6, 1)',
  Red500: 'rgba(221, 84, 72, 1)',
  Red200: 'rgba(241, 187, 182, 1)',
  Red100: 'rgba(248, 236, 235, 1)',
  Red50: 'rgba(165, 9, 6, 0.08)',
  HighlightRed: 'rgba(255, 128, 100, 1)',
  Yellow700: 'rgba(165, 88, 2, 1)',
  Yellow500: 'rgba(252, 188, 65, 1)',
  Yellow200: 'rgba(251, 233, 181, 1)',
  Yellow50: 'rgba(165, 88, 2, 0.08)',
  ForestGreen: 'rgba(33, 70, 61, 1)',
  Green700: 'rgba(18, 117, 75, 1)',
  Green500: 'rgba(73, 193, 136, 1)',
  Green200: 'rgba(200, 236, 219, 1)',
  Green50: 'rgba(18, 117, 75, 0.08)',
  NeonGreen: 'rgba(167, 255, 191, 1)',
  HighlightGreen: 'rgba(81, 233, 124, 1)',
  Olive700: 'rgba(72, 82, 69, 1)',
  Olive500: 'rgba(156, 172, 151, 1)',
  Olive200: 'rgba(215, 222, 213, 1)',
  Olive50: 'rgba(72, 82, 69, 0.1)',
};
